import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import { Notification, Checkbox, RadioButton, ToggleButton, SelectionGroup, Link } from "hds-react";
import ExternalLink from '../../../components/ExternalLink';
import LeadParagraph from '../../../components/LeadParagraph';
import PlaygroundPreview from '../../../components/Playground';
import InternalLink from '../../../components/InternalLink';
export const RadioButtonExample = () => {
  const [selectedItem, setSelectedItem] = React.useState("1");
  const onChange = event => {
    setSelectedItem(event.target.value);
  };
  return <SelectionGroup label="Choose your age group" mdxType="SelectionGroup">
      <RadioButton id="v-radio1" name="v-radio" value="1" label="Under 16" checked={selectedItem === "1"} onChange={onChange} mdxType="RadioButton" />
      <RadioButton id="v-radio2" name="v-radio" value="2" label="17-35" checked={selectedItem === "2"} onChange={onChange} mdxType="RadioButton" />
      <RadioButton id="v-radio3" name="v-radio" value="3" label="35-64" checked={selectedItem === "3"} onChange={onChange} mdxType="RadioButton" />
      <RadioButton id="v-radio4" name="v-radio" value="4" label="Over 65" checked={selectedItem === "4"} onChange={onChange} mdxType="RadioButton" />
    </SelectionGroup>;
};
export const CheckboxExample = () => {
  const [checked, setChecked] = React.useState(false);
  return <Checkbox id="checkbox" label="I have a loyalty card" checked={checked} onChange={() => setChecked(!checked)} mdxType="Checkbox" />;
};
export const SelectionGroupExample = () => {
  const [checkedItems, setCheckedItems] = React.useState({
    "v-checkbox1": true,
    "v-checkbox2": true
  });
  const onChange = event => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };
  return <SelectionGroup label="Select your interests" mdxType="SelectionGroup">
      <Checkbox id="v-checkbox1" name="v-checkbox1" label="Music" checked={checkedItems["v-checkbox1"]} onChange={onChange} mdxType="Checkbox" />
      <Checkbox id="v-checkbox2" name="v-checkbox2" label="Arts and museums" checked={checkedItems["v-checkbox2"]} onChange={onChange} mdxType="Checkbox" />
      <Checkbox id="v-checkbox3" name="v-checkbox3" label="Sports and outdoor activities" checked={checkedItems["v-checkbox3"]} onChange={onChange} mdxType="Checkbox" />
      <Checkbox id="v-checkbox4" name="v-checkbox4" label="Cooking" checked={checkedItems["v-checkbox4"]} onChange={onChange} mdxType="Checkbox" />
    </SelectionGroup>;
};
export const ToggleExample = () => {
  const [hasCustomTheme, setHasCustomTheme] = React.useState(false);
  const customTheme = {
    title: 'Primary theme',
    label: 'Use primary theme',
    baseStyles: {
      ['--label-color-default']: 'var(--color-coat-of-arms)'
    },
    titleStyles: {
      color: 'var(--color-coat-of-arms)'
    },
    toggle: {
      '--toggle-button-color': 'var(--color-coat-of-arms)',
      '--toggle-button-hover-color': 'var(--color-coat-of-arms)'
    }
  };
  return <div style={hasCustomTheme ? customTheme.baseStyles : {}}>
      <h2 style={hasCustomTheme ? customTheme.titleStyles : undefined}>
        {hasCustomTheme ? customTheme.title : 'Default theme'}</h2>
      <ToggleButton id="toggle-button-1" label={customTheme.label} checked={hasCustomTheme} theme={hasCustomTheme ? customTheme.toggle : {}} onChange={() => setHasCustomTheme(!hasCustomTheme)} mdxType="ToggleButton" />
    </div>;
};
export const _frontmatter = {};
const layoutProps = {
  RadioButtonExample,
  CheckboxExample,
  SelectionGroupExample,
  ToggleExample,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "checkboxes-radio-buttons-or-toggles",
      "style": {
        "position": "relative"
      }
    }}>{`Checkboxes, radio buttons, or toggles?`}<a parentName="h1" {...{
        "href": "#checkboxes-radio-buttons-or-toggles",
        "aria-label": "checkboxes radio buttons or toggles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Helsinki Design System has checkboxes, radio buttons and toggles that might seem similar to each other, but it is important to consider the use cases to decide which one to use.
    </LeadParagraph>
    <Notification label="TLDR (Too long, didn't read)" className="siteNotification" mdxType="Notification">
  <ul>
    <li><b>Use checkboxes</b> to select multiple parallel items.</li>
    <li><b>Use checkbox</b> for single selections to confirm on/off selection.</li>
    <li><b>Use radio buttons</b> to select mutually exclusive options.</li>
    <li><b>Use toggle</b> for selections affecting system settings or changing status.</li>
  </ul>
    </Notification>
    <h2 {...{
      "id": "when-to-use-radio-buttons",
      "style": {
        "position": "relative"
      }
    }}>{`When to use radio buttons?`}<a parentName="h2" {...{
        "href": "#when-to-use-radio-buttons",
        "aria-label": "when to use radio buttons permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <InternalLink size="M" href="/components/radio-button" mdxType="InternalLink">HDS RadioButton component documentation</InternalLink>
    <p>{`An application can use radio buttons when there is a list of two or more mutually exclusive choices and the user needs to select one of them. After clicking the non-selected radio button, whatever was previously selected is deselected. In traditional application design, the first radio button in the list was always selected by default. But not all modern websites follow this convention and rather use empty radio buttons by default. Websites today, forms, and applications inconsistently select one or leave all radio buttons blank by default. If none of the buttons is selected by default, users have no way to revert to this original state after they’ve made a selection. The lack of a standard can be confusing to users.`}</p>
    <p><strong parentName="p">{`HDS strongly recommends that radio buttons are never presented unticked.`}</strong></p>
    <p>{`Radio buttons have a lower cognitive load than dropdown menus because they make all options permanently visible so that users can easily compare them. Radio buttons are also easier to operate for users who have difficulty making precise mouse movements. (Limited space might sometimes force you to violate this guideline, but do try to keep choices visible whenever possible.)`}</p>
    <p><strong parentName="p">{`Example use:`}</strong>{` Binary choices like "Yes" or "No", age group or other similar choices that exclude other options.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <RadioButtonExample mdxType="RadioButtonExample" />
    </PlaygroundPreview>
    <h2 {...{
      "id": "when-to-use-checkboxes",
      "style": {
        "position": "relative"
      }
    }}>{`When to use checkboxes?`}<a parentName="h2" {...{
        "href": "#when-to-use-checkboxes",
        "aria-label": "when to use checkboxes permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <InternalLink size="M" href="/components/checkbox" mdxType="InternalLink">HDS checkbox component documentation</InternalLink>
    <p>{`Checkboxes allow the user to select zero, one, or multiple selections from a list of options. In other words, each checkbox is independent of the other checkboxes, i.e. checking one does not uncheck the others.`}</p>
    <p>{`A stand-alone checkbox is used for a single option that the user can turn on or off.`}</p>
    <p><strong parentName="p">{`Example use:`}</strong>{` Single selection confirming On/Off selection`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <CheckboxExample mdxType="CheckboxExample" />
    </PlaygroundPreview>
    <p><strong parentName="p">{`HDS recommends using checkboxes when the user is required to make more than two choices or confirm a single choice.`}</strong></p>
    <p><strong parentName="p">{`Example use:`}</strong>{` Selecting interests`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <SelectionGroupExample mdxType="SelectionGroupExample" />
    </PlaygroundPreview>
    <p><strong parentName="p">{`HDS recommends using checkboxes when the user can select multiple options that are not excluding other options.`}</strong></p>
    <h2 {...{
      "id": "when-to-use-toggles-or-switches",
      "style": {
        "position": "relative"
      }
    }}>{`When to use toggles or switches?`}<a parentName="h2" {...{
        "href": "#when-to-use-toggles-or-switches",
        "aria-label": "when to use toggles or switches permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <InternalLink size="M" href="/components/toggle-button" mdxType="InternalLink">HDS toggle component documentation</InternalLink>
    <p>{`Toggle switches are digital On/Off switches. They prompt users to choose between two mutually exclusive options and always have a default value. Toggle buttons have been inspired by real-world physical switches (e.g. a light switch). Therefore users expect something to happen immediately when the toggle button is activated. Toggle buttons should do three things — change states, show the current state, and reveal unselected options. If your toggle button doesn’t do all these things, it has poor usability. Toggles are best used for switching between two opposing states of system functionality and preferences. They are similar to radio buttons or checkboxes in that they allow users to select from only two options.`}</p>
    <p><strong parentName="p">{`Example use:`}</strong>{` Switching between themes.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <ToggleExample mdxType="ToggleExample" />
    </PlaygroundPreview>
    <h2 {...{
      "id": "sources-and-further-reading",
      "style": {
        "position": "relative"
      }
    }}>{`Sources and further reading`}<a parentName="h2" {...{
        "href": "#sources-and-further-reading",
        "aria-label": "sources and further reading permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Note that these articles might include guides that are not in line with HDS guidelines.`}</p>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://www.nngroup.com/articles/checkboxes-vs-radio-buttons/" mdxType="ExternalLink">Checkboxes vs. Radio Buttons, N&N Group</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.nngroup.com/articles/toggle-switch-guidelines/" mdxType="ExternalLink">Toggle-Switch Guidelines, N&N Group</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.nngroup.com/articles/radio-buttons-default-selection/" mdxType="ExternalLink">Radio Buttons: Always Select One? , N&N Group</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://uxmovement.com/buttons/the-challenges-with-single-toggle-buttons/" mdxType="ExternalLink">The Challenges with Single Toggle Buttons, UXMovement</ExternalLink>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      